<template>
  <AuditList />
</template>

<script>

import AuditList from '@/components/common/audit/AuditList.vue';

export default {
  name: 'AuditLog',
  components: {
    AuditList,
  },
};
</script>
