<template>
    <v-container>
      <CRUDCriteria
        :showSearch="false"
        :showReset="true"
      >
        <template v-slot:criteriaRows>
          <v-row>
            <v-col cols="2">
              <v-select id="tmc-audit-criteria-program"
                v-model="criteriaState.program"
                :items="programs"
                item-text="text"
                item-value="value"
                label="By Program"
                @change="searchByCriteria"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select id="tmc-audit-criteria-type"
                v-model="criteriaState.type"
                :items="types"
                item-text="text"
                item-value="value"
                label="By Type"
                @change="searchByCriteria"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select id="tmc-audit-criteria-subtype"
                v-model="criteriaState.subtype"
                :items="subtypes"
                label="By Subtype"
                @change="searchByCriteria"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field id="tmc-audit-criteria-username"
                v-model="criteriaState.username"
                label="By username (exact)"
                @change="searchByCriteria"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <ClarionDateControl classes="tmc-audit-criteria-when-from"
              v-model="criteriaState.whenFrom"
              :isRequired="false"
              :isIsoDate="true"
              @blurred="searchByCriteria"
              :label="`From (When)`" />
            <ClarionDateControl classes="tmc-audit-criteria-when-to"
              v-model="criteriaState.whenTo"
              :isRequired="false"
              :isIsoDate="true"
              @blurred="searchByCriteria"
              :label="`From (To)`" />
          </v-row>
        </template>
      </CRUDCriteria>
      <CRUDList
        :canAdd="false"
        :canEdit="true"
        :canSave="false"
        :canDelete="false"
        :baseCrudKey="baseCrudKey"
        :headers="headers"
        :mergeCriteria="false"
        toolbarTitle="Audit Items"
        @loadCRUDList="handleLoadCRUDList"
      >
        <template v-slot:[`editedItem`]="{}">
          <AuditEditedItem :baseCrudKey="baseCrudKey" />
        </template>
      </CRUDList>
    </v-container>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import {

  toLocalDateTime,
} from '../../../util/shared/vue-global';

import CRUDCriteria from '../base/crud/CRUDCriteria.vue';
import CRUDList from '../base/crud/CRUDList.vue';
import ClarionDateControl from '../ClarionDateControl.vue';
import AuditEditedItem from './AuditEditedItem.vue';

const fields = {
  _id: '',
  program: '',
  type: '',
  subtype: '',
  username: '',
  when: new Date(),
  remarks: '',
};

const getHeaders = (canDelete) => {
  const headers = [
    // { text: 'ID', value: '_id' },
    { text: 'Program', value: 'program' },
    { text: 'Type', value: 'type' },
    { text: 'SubType', value: 'subtype' },
    { text: 'User', value: 'username' },
    { text: 'Date/Time', value: 'when', formatter: 'localDateTime' },
  ];
  if (canDelete) {
    headers.push({ text: 'Actions', value: 'actions', sortable: false });
  }
  return headers;
};

const subtypes = [
  '',
  'import',
  'create',
  'update',
  'delete',
  'delete image',
];

export default {
  name: 'AuditList',
  components: {
    CRUDCriteria,
    CRUDList,
    ClarionDateControl,
    AuditEditedItem,
  },
  data: () => ({
    baseCrudKey: 'audit',
    headers: [],
    programs: [
      { text: ' ', value: '' },
      { text: 'Official Depository', value: 'OD' },
      { text: 'Warrants & Appropriations', value: 'war' },
      { text: 'Payroll Register', value: 'payroll' },
      { text: 'Miscellaneous Receipts', value: 'misc' },
      { text: 'Mortgage Tax', value: 'mtg' },
    ],
    types: [
      { text: ' ', value: '' },
      { text: ' Official Depository Transaction', value: 'ODTransaction' },
      { text: 'Warrants & Appropriations', value: 'warTransaction' },
      { text: 'Warrants Account Transaction', value: 'warAccount' },
      { text: 'Payroll Register', value: 'payrollTransaction' },
      { text: 'Miscellaneous Receipts Transaction', value: 'miscTransaction' },
      { text: 'Mortgage Tax Transaction', value: 'mtgTransaction' },
    ],
    subtypes,
  }),
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
    ]),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
  },
  created() {
    this.headers = getHeaders(this.canDelete);
  },
  methods: {
    ...mapActions('audit', [
      'loadAudits',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    toLocalDateTime,
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteria(baseCrudKey);
      this.loadAudits({
        criteria,
        fields,
        baseCrudKey,
      });
    },
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
  },
};
</script>
